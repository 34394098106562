.customDatePicker {
  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-table-header {
    margin: 8px 0;

    tr {
      th {
        @apply text-[theme('colors.navy')] capitalize text-sm font-bold;
        padding: 8px 0;
      }
    }
  }

  .mat-calendar-body tr:first-of-type td:first-child:not([role='gridcell']) {
    visibility: hidden;
  }

  .mat-calendar-body tr:first-of-type td:only-child {
    display: none;
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  .mat-calendar-body-today {
    border-color: theme('colors.smoke') !important;
  }

  mat-year-view .mat-calendar-body-cell-content,
  mat-multi-year-view .mat-calendar-body-cell-content {
    border-radius: 0.5rem;
    text-transform: capitalize;
  }

  .mat-calendar-body-cell-content {
    color: theme('colors.navy');
  }

  .mat-calendar-body-cell-content.mat-calendar-body-selected {
    background-color: theme('colors.teal.600') !important;
    color: theme('colors.white') !important;
  }

  .cdk-program-focused {
    .mat-calendar-body-active > .mat-calendar-body-cell-content {
      border: 0.125rem solid;
      border-color: theme('colors.teal.500') !important;
      background-color: white !important;
      color: theme('colors.navy');
    }
    .mat-calendar-body-cell-content.mat-calendar-body-selected {
      background-color: theme('colors.teal.600') !important;
    }
  }

  .cdk-keyboard-focused {
    .mat-calendar-body-active > .mat-calendar-body-cell-content {
      border: 0.125rem solid;
      border-color: theme('colors.teal.500') !important;
      background-color: white !important;
      color: theme('colors.navy');
    }
    .mat-calendar-body-cell-content.mat-calendar-body-selected {
      background-color: theme('colors.teal.600') !important;
    }
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: theme('colors.neutral.200');
  }

  .mat-calendar-body-cell:active {
    .mat-calendar-body-cell-content {
      background-color: theme('colors.neutral.300') !important;
    }
  }
}

.customDatePicker.mat-calendar {
  width: 383px !important;
  margin: 8px;
}

.customToggleDatePicker.mat-datepicker-toggle button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
}
