// @import './theme';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// OVERLAY
@import '@angular/cdk/overlay-prebuilt.css';

// ABSTRACTS
@import 'abstracts/breakpoints';
@import 'abstracts/fonts';
@import 'abstracts/functions';
@import 'abstracts/mixins';
// BASE
@import 'base/button';
@import 'base/line';
@import 'base/reset';
@import 'base/typography';
@import 'base/modal';
@import 'base/datepicker';

// Material theme
@import '_theme';

@import 'stripe';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(68, 87, 119);
  transition: background-color 500s ease-in-out 0s;
}
