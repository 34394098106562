@tailwind components;
@import 'abstracts/functions';

$focus-visible-box-shadow: 0 0 0 2px theme('colors.white'),
  0px 0px 0px 4px theme('colors.teal.500');

button {
  &:disabled {
    color: theme('colors.smoke') !important;

    &:not(.btn-inline) {
      border-color: theme('colors.platinum') !important;
      background-color: theme('colors.cloudy') !important;
    }
  }
}

@layer components {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calculateRem(16);
    font-weight: theme('fontWeight.bold');
    line-height: 1.56;
    text-align: center;
    user-select: none;
    padding: calculateRem(12) calculateRem(24);
    border-radius: theme('borderRadius.default');

    &-no-padding {
      padding: 0;
    }

    &-inline {
      color: theme('colors.teal.darkest.100');
      border: solid calculateRem(1) theme('colors.neutral.200');

      &:hover {
        background-color: theme('colors.neutral.200');
        border: solid calculateRem(1) theme('colors.neutral.200');
      }

      &:active {
        background-color: theme('colors.neutral.300');
        border: solid calculateRem(1) theme('colors.neutral.300');
      }
    }

    &-primary {
      border: solid calculateRem(1) theme('colors.teal.darkest.100');
      background-color: theme('colors.teal.darkest.100');
      color: theme('colors.white');

      &:hover {
        border-color: theme('colors.teal.darkest.200');
        background-color: theme('colors.teal.darkest.200');
        color: theme('colors.white');
      }

      &:active {
        border-color: theme('colors.teal.darkest.300');
        background-color: theme('colors.teal.darkest.300');
      }

      &.selected {
        align-items: center;
        border-color: theme('colors.teal.darkest.300');
        background-color: theme('colors.teal.darkest.300');

        &:hover {
          background-color: theme('colors.teal.darkest.100');
        }
      }
    }

    &-secondary {
      border: solid calculateRem(1) theme('colors.teal.light.100');
      background-color: theme('colors.teal.light.100');
      color: theme('colors.teal.darkest.100');

      &:hover {
        border-color: theme('colors.teal.light.200');
        background-color: theme('colors.teal.light.200');
      }

      &:active {
        border-color: theme('colors.teal.light.300');
        background-color: theme('colors.teal.light.300');
      }
    }

    &-tertiary {
      border-color: transparent;
      background-color: transparent;
      color: theme('colors.teal.600');

      &:hover {
        border-color: theme('colors.platinum');
        background-color: theme('colors.platinum');
        @apply bg-teal-600/5;
        color: theme('colors.navy');
      }

      &:focus {
        @apply shadow-button-focus border-aqua-default/25 shadow-aqua-default/25;
        background-color: transparent;
        color: theme('colors.teal.600');
      }

      &:active {
        border-color: transparent;
        background-color: transparent;
        color: theme('colors.aqua.darker');
      }
    }

    &-large {
      font-size: calculateRem(18);
      line-height: 1.56;

      &:not(.btn-inline) {
        padding: calculateRem(14) calculateRem(28);
      }
    }

    &-small {
      font-size: calculateRem(16);
      line-height: 1.5;

      &:not(.btn-inline) {
        padding: calculateRem(8) calculateRem(16);
      }
    }

    &-xsmall {
      font-size: calculateRem(14);
      line-height: 1.43;

      &:not(.btn-inline) {
        padding: calculateRem(6) calculateRem(12);
      }
    }

    &:focus-visible {
      @apply bg-lex-primary-teal-darkest shadow-[0px_0px_0px_2px_#FFF,0px_0px_0px_4px_#009EAD] outline-none;
    }
  }
}
