.control--stripe {
  border: 1px solid theme('colors.platinum');
  border-radius: theme('borderRadius.sm');
  padding: theme('spacing.9') theme('spacing.4') theme('spacing.4')
    theme('spacing.16');
  background-color: theme('colors.white');

  &-- {
    &focused {
      border-bottom-color: theme('colors.teal.400');
    }
    &filled {
    }
    &invalid {
      border-color: theme('colors.pale.red');
    }

    &empty {
    }
  }
}
