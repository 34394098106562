.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  padding: 20px;
  z-index: 1000;
  background-color: rgba(10, 20, 32, 0.4);
  overflow: auto;
}

.modal-body {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.modal-open {
  overflow: hidden;
}
